<template>
    <div class="app-container">
        <div class="app-top-btn">
            <el-date-picker
                type="daterange"
                size="medium"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="searchDatePickerOptions"
                v-model="searchDatePicker"
                @change="searchDate"
            >
            </el-date-picker>
            <el-input
                style="width: 400px; float: right"
                placeholder="关键字"
                size="medium"
                class="input-with-select"
                v-model="listQuery.keyWords"
            >
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="searchKeyWords"
                ></el-button>
            </el-input>
        </div>
        <el-table :data="list" border fit highlight-current-row>
            <el-table-column align="center" label="交易编号" width="220">
                <template slot-scope="scope">
                    <span>{{ scope.row.orderNum }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="商家名称">
                <template slot-scope="scope">
                    <span>{{ scope.row.storeName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="用户姓名" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.userName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="用户手机" width="130">
                <template slot-scope="scope">
                    <span>{{ scope.row.phone }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="交易金额" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="实付金额" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.stlAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="交易时间" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="退款" width="120px">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.returnSign === 0"
                        size="mini"
                        type="primary"
                        disabled
                    >
                        暂无退款
                    </el-button>
                    <el-button
                        v-else-if="scope.row.returnSign === 1"
                        size="mini"
                        type="primary"
                        @click="getRefundList(scope.row)"
                    >
                        退款记录
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>

        <!--退款记录-->
        <el-dialog
            title="退款记录"
            :visible.sync="refundDialog"
            width="520px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-table :data="refundList" border fit highlight-current-row>
                <el-table-column align="center" label="交易编号" width="220">
                    <template slot-scope="scope">
                        <span>{{ scope.row.orderNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="退款金额">
                    <template slot-scope="scope">
                        <span>{{ scope.row.amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="退款时间" width="160">
                    <template slot-scope="scope">
                        <span>{{ scope.row.createTime }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                startTime: '', //查询开始日期
                endTime: '', //查询截止日期
                keyWords: '', //查询关键字
                // belongId: 0,
                selectType: 1, //0是商家,1是单位
            },
            searchDatePicker: [], //查询日期范围选择
            searchDatePickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                },
            },
            refundDialog: false, //退款记录弹窗
            refundList: [], //退款记录
        }
    },
    created() {
        // this.listQuery.belongId = this.$store.state.user.departInfo.id
        //获取列表
        this.getList()
    },
    methods: {
        //分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        //查询日期
        searchDate() {
            this.listQuery.pageNum = 1
            this.listQuery.startTime = this.searchDatePicker[0]
            this.listQuery.endTime = this.searchDatePicker[1]
            this.getList()
        },
        //查询关键字
        searchKeyWords() {
            if (this.listQuery.type === '') {
                this.$message({
                    duration: 1000,
                    message: '请选择查询类型',
                    type: 'error',
                })
                return false
            } else if (this.listQuery.keyWords === '') {
                this.$message({
                    duration: 1000,
                    message: '请输入查询关键字',
                    type: 'error',
                })
                return false
            } else {
                this.listQuery.pageNum = 1
                this.getList()
            }
        },
        //获取列表
        getList() {
            this.$api.order_page(this.listQuery).then((res) => {
                this.listTotal = res.pageInfo.total
                this.list = res.data
            })
        },
        //获取退款记录列表
        getRefundList(item) {
            this.$http
                .get('/refundlist/list', {
                    params: {
                        orderNum: item.orderNum,
                    },
                })
                .then((res) => {
                    this.refundList = []
                    this.refundList = res.data
                    this.refundDialog = true
                })
        },
    },
}
</script>
